@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

html.menu-open {
  overflow: hidden;
}

@layer base {
  body {
    color: theme("colors.white");
    background-color: var("colors.dark");
    font-family: theme("fontFamily.body");
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: theme("fontFamily.heading");
    font-weight: 400;
    line-height: 1;

    word-wrap: break-word;
  }

  h1 {
    font-size: theme("fontSize.xxl");
  }

  h2 {
    font-size: theme("fontSize.xl");
  }

  h3 {
    font-size: theme("fontSize.l");
  }

  h4 {
    font-size: theme("fontSize.m");
  }

  @media (max-width: theme("screens.md")) {
    h1 {
      font-size: theme("fontSize.xl");
    }

    h2 {
      font-size: theme("fontSize.l");
    }

    h3 {
      font-size: theme("fontSize.m");
    }

    h4 {
      font-size: theme("fontSize.s");
    }
  }

  p,
  li,
  input,
  textarea {
    font-family: theme("fontFamily.body");
    font-size: theme("fontSize.m");
    line-height: 1.75rem;
  }

  @media (max-width: theme("screens.md")) {
    p,
    li,
    input,
    textarea {
      font-size: theme("fontSize.s");
      line-height: 1.4375rem;
    }
  }

  a,
  button {
    font-size: theme("fontSize.m");
    line-height: 1;
  }

  h1:not(:last-child),
  h2:not(:last-child),
  h3:not(:last-child),
  h4:not(:last-child),
  ul:not(:last-child),
  p:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  p > a {
    text-decoration: underline;
  }

  p + a,
  p + h1,
  p + h2,
  p + h3,
  p + h4 {
    margin-top: 3rem;
  }

  ul {
    margin-left: 1.125rem;
    list-style-type: disc;
  }

  figcaption {
    font-size: theme("fontSize.s");
    line-height: 1.4375rem;
  }

  textarea {
    resize: none;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@font-face {
  font-family: "Nohemi";
  src: url("/fonts/NohemiVF.ttf") format("truetype");
  font-weight: 100 900;
  font-style: normal;
}
